import React from 'react';

import { Jumbotron, SecondaryJumbotron } from '../components/homepage/jumbotron';

export function Home() {
  return (
    <div className="pull-center">
      <Jumbotron />
    </div>
  );
}
